import { List, ListItem, ListItemAvatar, ListItemButton, ListItemText, styled } from "@mui/material";
import { GroupDto, UserStatus } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAllUsers } from "../../domain/users.ts";
import { getFullGroupPath, getUserIdsFromGroupAndChildGroups } from "../../lib/groups";
import { translationKeys } from "../../translations/main-translations";
import Tooltip, { OverflowOrientation } from "../Tooltip";
import { GroupDetailsPopover } from "./GroupDetailsPopover.tsx";
import GroupAvatar from "../usergrouppermissions/GroupAvatar";

interface GroupDetailsProps {
    groupId: number;
    groups: GroupDto[];
    inline?: boolean;
}

const ListItemButtonNoHover = styled(ListItemButton)(() => ({
    ":hover": {
        backgroundColor: "inherit",
    },
}));

const GroupDetails = ({ groupId, groups, inline }: GroupDetailsProps) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const group = groups.find(({ id }) => id === groupId);
    const users = useAllUsers();
    const activeUsers = users.filter((u) => u.status === UserStatus.STATUS_ACTIVE);

    if (group === undefined) {
        return null;
    }

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    const userIdsInGroup = getUserIdsFromGroupAndChildGroups(groups, groupId);
    const activeUsersInGroup = activeUsers.filter((u) => userIdsInGroup.includes(u.id));
    const usersCount = activeUsersInGroup.length;
    const name = getFullGroupPath(groups, group)
        .map((group) => group.name)
        .join(" > ");

    return (
        <List component="div" dense disablePadding sx={{ width: "100%" }}>
            <ListItem component="div" disableGutters disablePadding>
                <Tooltip
                    placement="bottom-start"
                    title={group.description != undefined && group.description.length > 0 ? `${name} - ${group.description}` : name}
                    orientation={OverflowOrientation.Horizontal}
                >
                    {inline === true ? (
                        <ListItemButtonNoHover disableGutters sx={{ py: 0 }}>
                            <ListItemAvatar onClick={handleClick} sx={{ minWidth: 0, mr: 1 }}>
                                <GroupAvatar size={24} />
                            </ListItemAvatar>
                            <ListItemText
                                onClick={handleClick}
                                primary={name}
                                primaryTypographyProps={{ noWrap: true, variant: "body2" }}
                            />
                        </ListItemButtonNoHover>
                    ) : (
                        <ListItemButton disableGutters sx={{ py: 0 }}>
                            <ListItemAvatar onClick={handleClick} sx={(t) => ({ minWidth: 40, mr: t.spacing(1) })}>
                                <GroupAvatar />
                            </ListItemAvatar>
                            <ListItemText
                                onClick={handleClick}
                                primary={name}
                                primaryTypographyProps={{
                                    noWrap: true,
                                    variant: "body2",
                                    display: "block",
                                    textOverflow: "ellipsis",
                                    maxWidth: 400,
                                }}
                                secondary={t(translationKeys.VDLANG_USERS, { count: usersCount })}
                                secondaryTypographyProps={{ noWrap: true, variant: "caption", display: "block", lineHeight: 1 }}
                            />
                        </ListItemButton>
                    )}
                </Tooltip>
            </ListItem>
            <GroupDetailsPopover groupId={group.id} groupUsers={activeUsersInGroup} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
        </List>
    );
};

export default GroupDetails;
